import { useState } from 'react';

import styles from '../styles/Accordion.module.css';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
    <div className={styles.accordion_item}>
      <div className={styles.accordion_title} onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className={styles.accordion_content}> <p>{content}</p></div>}
    </div>
    </>

  );
};

export default Accordion;
