import './App.css';
import './variables.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Team from './pages/Team';
import Alumni from './pages/Alumni';
import Events from './pages/Events';
// import SnowParticles from './components/SnowParticles';
function App() {
  return (
   <>
    <Router>
    <Navbar/>

      <AllRoutes/>
    </Router>
    <Footer/>
   </>
  );
}
const AllRoutes = () => {
  return(
  <Routes>
    {/* <Route path="/" element={<Home/>} /> */}
    <Route path="/" element={<Home/>} />
    <Route path="/team" element={<Team/>} />
    <Route path="/alumni" element={<Alumni/>} />
    <Route path="/events" element={<Events/>} />
  </Routes>
  )
}
export default App;
