import TeamCard from '../components/TeamCard';

import styles from '../styles/Team.module.css';
const Alumni = () => {
    const alumni = 
    [
        {
          "name": "Bharath Kumar",
          "position": "Chairperson",
          "imageSrc": "/assets/img/alumni/Chairperson_Bharath kumar.jpg"
        },
        {
          "name": "Asmitha Eswaran",
          "position": "Vice Chairperson",
          "imageSrc": "/assets/img/alumni/Vice_Chair_Asmitha Eswaran.jpg"
        },
        {
          "name": "Pranava Raman",
          "position": "Secretary",
          "imageSrc": "/assets/img/alumni/Secretary_Pranava Raman BMS.jpeg"
        },
        {
          "name": "Akshayalakshmi",
          "position": "Secretary",
          "imageSrc": "/assets/img/alumni/Secretary_Akshayalakshmi V K.jpeg"
        },
        {
          "name": "Vasudha",
          "position": "Treasurer",
          "imageSrc": "/assets/img/alumni/Treasurer_VASUDHA E.jpg"
        },
        {
          "name": "Pramodh",
          "position": "Director of Events",
          "imageSrc": "/assets/img/alumni/Director_of_Events_PramodhV.jpeg"
        },
        {
          "name": "Hema Shirisha",
          "position": "Director of Events",
          "imageSrc": "/assets/img/alumni/Director_of_Events_Hema Shirisha.jpg"
        }
      ]
      
    return(
        <>
         <div className={styles.body_wrapper} style={{backgroundImage:'url(assets/img/polygon.svg)'}}>
        <h1 className="section-header">Office Bearers - 2022-2023</h1>

        <div className={styles.card_container}>
          {alumni.map((alumni) => 
          <>
          <TeamCard name={alumni.name} position={alumni.position} imageSrc={alumni.imageSrc} linkedinUrl={alumni.linkedinUrl}/>
          </>)}
        </div>
        </div>
        </>
    )
}

export default Alumni;