
import BgParticles from '../components/SnowParticles';
import HomeCard from '../components/HomeCard';

import styles from '../styles/Home.module.css'
import Accordion from '../components/Accordion';


const Home = () => {
    const faq = [
        {
            title:"What is ACM?",
            content:"The ACM-CEG Student Chapter, is an official student body that has been steadily working to inculcate an unalloyed interest for Computer Science in students and consequently, stimulating the advancement of computer science as a whole."
        },
        {
            title:"What have we got in store for you?",
            content:"We conduct events such as CODHER to promote women participation in technology, PRODIGY to instill computer science interest among school students, webinars and alumni talks for career guidance."
        },
        {
            title:"What programs do we conduct?",
            content:"We hold two main programs, namely C.O.D.E and HEAD FIRST.C.O.D.E targets pre-final and final year students to help them prepare for their interviews and placements.Head first targets first year students and help them understand data structures and algorithm used in competitive programming."
        },
        {
            title:"What is our motive?",
            content:"We aim to motivate students to embark on a journey of learning and developing the tools of computer science and instilling in them a passion for computer science.We serve to clear the clutter and provide guidance to help them to achieve their goals."
        },
        {
            title:"How do we send to you opportunities?",
            content:"We use Newsletter as a tool to share relevant and valuable information through the network. Enter your email at the Newletter section, we'll be sending you event updates and opportunities.We present 'Offcamp'-An Off-Campus opportunities sharing program for scholarships,internships,job openings and much more through telegram"
        },
        {
            title:"What is the technical fest we bring to you?",
            content:"ACM-CEG presents to you TECHFIESTA during march every year - A multi-domain lineup and divergent events to bring out the technical and creative intelligence inside you."
        }
    ]
    return(
        <>
  <section className={styles.hero}>
  <div className={styles.particles_container}><BgParticles/></div>
    <div className={styles.hero_container}>
      <h1><span style={{color:'var(--primary-pink)'}}>ACM</span> - <span style={{color:'var(--primary-pink)'}}>CEG</span><br/>STUDENT CHAPTER</h1>
      <p>We <b>Association for Computing Machinery</b>, aim to motivate students to embark on a journey of learning and developing the tools of
        computer science and instilling in them a passion for computer science.We serve to clear the clutter and provide
        guidance to help them to achieve their goals</p>

    </div>
  </section>

  <section className={styles.card_container} 
  >
    <HomeCard heading={"About Us"}
     content={"The ACM-CEG Student Chapter, initiated in 2004, aims to instill an unwavering enthusiasm for computer science in students. The club provides a plethora of networking opportunities and helps to seek advice from the top experts in the field. The club has been steadily working to inculcate an unalloyed interest for Computer Science in students and consequently, stimulating the advancement of computer science as a whole."}
      imgUrl={"/img/ACM.png"}/>
    <HomeCard heading={"Codher"}
     content= {"Codeher, an annual state-level technical event organized by the ACM-CEG chapter at Anna University, is a pioneering initiative designed exclusively for students in grades 9-12. Rooted in the ethos of promoting technological awareness and fostering a passion for computer science, Prodigy goes beyond traditional education, providing a dynamic platform for young minds to delve into the exciting realms of computing and technology"} 
     imgUrl={"/img/codherLogo.png"}/>
    <HomeCard heading={"Prodigy"} 
    content = {"Prodigy, an annual state-level technical event organized by the ACM-CEG chapter at Anna University, is a pioneering initiative designed exclusively for students in grades 9-12. Rooted in the ethos of promoting technological awareness and fostering a passion for computer science, Prodigy goes beyond traditional education, providing a dynamic platform for young minds to delve into the exciting realms of computing and technology. Over the span of more than."} 
    imgUrl={"/img/prodigyLogo.png"}/> 
  </section>

  <section className={styles.faq}>
    <h1>Frequently Asked Questions</h1>
    <div className={styles.accordion_container}>
        {faq.map((ele) => {
            return(
                <Accordion title={ele.title} content={ele.content}/>
            )
        })}
    </div>

  </section>


</>
    )
}

export default Home;