import styles from "../styles/EventCard.module.css"
const EventCard = ({title, content, imageSrc}) => {
    return (
      <>
        <div className={`${styles.tilting_card_content}`}>
          <div className = {styles.img_container}><img src={imageSrc} alt="event" className={styles.events_img} /></div>
          <div className={styles.events_content}>
            <h3>{title}</h3>
            <p>{content}</p>
          </div>

          <div className={styles.mouse_position_tracker}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
}

export default EventCard;