import EventCard from "../components/EventCard";

const Events = () => {
    const events = [
        {
            "title": "C.O.D.E",
            "content": "Embark on a transformative journey with C.O.D.E, our premier event meticulously crafted to equip students for internship and placement interviews. C.O.D.E aims to empower participants by immersing them in carefully curated problem sheets, thoughtfully categorized by topics, fostering skill fortification across crucial areas. Engage in distinct contests tailored to each topic, providing a laser-focused mastery experience. The grand finale contest awaits to evaluate your comprehensive understanding and showcase your prowess. Access exclusive materials encompassing vital interview aspects such as OS, DBMS, Networks, and System Design. C.O.D.E aspires to guide you through this process with experienced mentors assigned throughout the event. Elevate your preparation through a mock interview experience, ensuring you emerge truly interview-ready.",
            "imageSrc": "assets/img/events/code.jpeg"
          },
          {
            "title": "CodeX ",
            "content": "CodeX is our dedicated event tailored to enhance students' competitive programming skills. CodeX revolves around a series of challenging contests designed to assess participants' problem-solving abilities. Dive into the world of competitive programming with insightful editorials for each contest, offering valuable perspectives on optimal problem-solving approaches. CodeX goes beyond contests by hosting informative sessions covering the best algorithms, mathematical concepts, and data structures essential for mastering competitive programming. Additionally, gain valuable insights into the prestigious International Collegiate Programming Contest (ICPC) through exclusive information sessions. Join CodeX to sharpen your coding prowess and unlock the secrets to success in the dynamic realm of competitive programming.",
            "imageSrc": "assets/img/events/codex.jpeg"
          },
          {
            "title": "Head First",
            "content": "Head First is a tailored event designed exclusively for first-year students. Similar to CodeX, this initiative prioritizes the unique needs of beginners, offering challenges and problem sets curated to align with their introductory programming and basic data structure understanding. Through Head First, first-year students are guided through a learning journey that familiarizes them with programming logic and essential data structures. Join us in laying the foundation for a successful coding journey and witness the transformative experience of diving into the world of programming at its very beginning.",
            "imageSrc": "assets/img/events/headfirst.png"
          },
          {
            "title": "OffCamp",
            "content": "Unlock a world of opportunities with OffCamp, a dynamic program designed to actively connect students with a plethora of opportunities. Dive into a rich pool of scholarships, internships, job openings, and more by becoming a part of our OffCamp community. This initiative serves as your gateway to easily access a diverse range of opportunities tailored to your academic and professional journey. Join OffCamp and stay ahead in your pursuit of success, as we strive to empower you with the latest and most relevant openings in the realms of scholarships, internships, and job opportunities. Seize the chance to enhance your academic and professional growth with OffCamp.",
            "imageSrc": "assets/img/events/offcamp.png"
          },
          {
            "title": "Schr0ding3r5",
            "content": "Step into the realm of cybersecurity with Schr0ding3r5, the cybersecurity wing of ACM-CEG. Our mission is to spread awareness about competitive security and enhance the overall perception of cybersecurity among students. Engage in events crafted to bring together cybersecurity enthusiasts in the college, fostering a community that actively contributes to the evolving landscape of digital security. Join us in exploring the fascinating world of Schr0ding3r5, where we strive to empower students with knowledge, skills, and a passion for competitive security. Together, let's fortify our understanding of cybersecurity and contribute to a safer digital future.",
            "imageSrc": "assets/img/events/schrodinger.png"
          },        
    ]
    return(
        <>
        <div style={{backgroundImage:'url(assets/img/polygon.svg)', padding:'2rem'}}>
        <section >
        <h1 className="section-header">Events</h1>
            {events.map((event) => 
            <EventCard title={event.title} content={event.content} imageSrc={event.imageSrc} />
            )
            }
        </section>
        </div>
        </>
    )
}

export default Events;