import { Link } from "react-router-dom"

import styles from '../styles/Navbar.module.css'

const Navbar = () => {
    return(
        <>
  <div className={styles.nav_container}>
  <h1 className={styles.logo}><Link to = "/"><img src='/img/ACM.png'/></Link></h1>
  <input type="checkbox" id="nav_toggle" className={styles.nav_toggle}/>
  <nav>
    <ul>
      <li><Link to = "/events">Events</Link></li>
      <li><Link to = "/team">Team</Link></li>
      <li><Link to = "/alumni">Alumni</Link></li>
    </ul>
  </nav>
  <label for="nav_toggle" className={styles.nav_toggle_label}>
    <span></span>
  </label>
</div>
        </>
    )
}

export default Navbar;