import styles from '../styles/TeamCard.module.css';
const TeamCard = ({name, position, imageSrc, linkedinUrl}) => {
    return(
        <>
        <div className = {styles.wrapper}>
            <div className={styles.img_container}>
                <img src={imageSrc} alt="team member" className={styles.profile_img}/>
            </div>
            <div className={styles.content_container}>
                <h2>{name}</h2>
                <p><a href={linkedinUrl}> </a></p>
            </div>
            <div className={styles.positionwrapper}>
                <p>{position}</p>
            </div>
        </div>
        </>
    )
}

export default TeamCard;