import TeamCard from "../components/TeamCard"

import styles from "../styles/Team.module.css"
const Team = () => {
    const directors = [
        {
            name: "Arunachalam M",
            position: "Chairperson",
            imageSrc: "/assets/img/team/directors/Chair_Arunachalam.jpg",
            linkedinUrl: "https://www.linkedin.com/in/arunachalam-manikandan/"
          },
          {
            name: "Rishitha N",
            position: "Vice Chairperson",
            imageSrc: "/assets/img/team/directors/Vice_Chair_Rishitha.jpg",
            linkedinUrl: "https://www.linkedin.com/in/rishitha-n-a19882227/"
          },
          {
            name: "Ashwin Muthuraman A",
            position: "Secretary",
            imageSrc: "/assets/img/team/directors/Secratary_Ashwin_Muthuraman.jpg",
            linkedinUrl: "http://linkedin.com/in/a-ashwin-muthuraman"
          },
          {
            name: "Shivaani S",
            position: "Treasurer",
            imageSrc: "assets/img/team/directors/Treasurer_Shivaani.jpeg",
            linkedinUrl: "https://www.linkedin.com/in/shivaani-s-9b067b21a/"
          },
          {
            name: "Sathya Naraayanaa S",
            position: " Events",
            imageSrc: "assets/img/team/directors/Events_Sathya_Naraayanaa.jpg",
            linkedinUrl: "https://www.linkedin.com/in/sathya-naraayanaa-s/"
          },
          {
            name: "Mithulesh P",
            position: " Design",
            imageSrc: "assets/img/team/directors/Design_Mithulesh.jpg",
            linkedinUrl: "https://www.linkedin.com/in/mithulesh-p-09a06420b/"
          },
          {
            name: "Kavin Prasath K",
            position: " Design",
            imageSrc: "assets/img/team/directors/Design_KavinPrasath.jpg",
            linkedinUrl: "https://www.linkedin.com/in/kavin-prasath-k-3055b520a"
          },
          {
            name: "Vidharshana Ravi",
            position: " Content",
            imageSrc: "assets/img/team/directors/Content_vidharshanaRavi.jpg",
            linkedinUrl: "https://www.linkedin.com/in/vidharshana-ravi-104934211/"
          },
          {
            name: "Kathir R M",
            position: " Web and App",
            imageSrc: "assets/img/team/directors/Web_kathir.jpeg",
            linkedinUrl: "https://www.linkedin.com/in/rmkathir/"
          },
          {
            name: "Charanya M",
            position: " Marketing",
            imageSrc: "assets/img/team/directors/Marketing_Charanya.jpg",
            linkedinUrl: "https://www.linkedin.com/in/charanya-manivannan/"
          },
          {
            name: "Sarvesh E",
            position: " HR",
            imageSrc: "assets/img/team/directors/HR_Sarvesh.jpg",
            linkedinUrl: "https://www.linkedin.com/in/sarvesh-e/"
          },
          {
            name: "Senthamizhan",
            position: " CP wing",
            imageSrc: "assets/img/team/directors/CP_Senthamizhan.jpg",
            linkedinUrl: "https://www.linkedin.com/in/senthamizhan/"
          },
          {
            name: "Navaneetha Krishnan R",
            position: " CP wing",
            imageSrc: "assets/img/team/directors/CP_Navaneetha_Krishnan.jpg",
            linkedinUrl: "https://www.linkedin.com/in/navaneetha-krishnan-r-38998420a/"
          },
          {
            name: "Girish S",
            position: " CP wing",
            imageSrc: "assets/img/team/directors/CP_Girish.jpg",
            linkedinUrl: "https://www.linkedin.com/in/girish170902/"
          },
    ]

    const deputyDirectors = [
        {
            name: "Sudeep R",
            position: "Web and App",
            imageSrc: "assets/img/team/deputydirectors/Web_Sudeep.jpg",
            linkedinUrl: "https://www.linkedin.com/in/sudeep72/"
          },
          {
            name: "Nikhil Prasanna A",
            position: "Web and App",
            imageSrc: "assets/img/team/deputydirectors/Web_Nikhil.jpg",
            linkedinUrl: "https://www.linkedin.com/in/nikhil03a/"
          },
          {
            name: "Subasini J",
            position: "Design",
            imageSrc: "assets/img/team/deputydirectors/Design_Subasini.jpg",
            linkedinUrl: "https://www.linkedin.com/in/subasini-jaganathan"
          },
          {
            name: "Swathi K",
            position: "Design",
            imageSrc: "assets/img/team/deputydirectors/Design_Swathi.jpg",
            linkedinUrl: "https://www.linkedin.com/in/swathi-k-6b648b263"
          },
          {
            name: "Dhivyadharshini S B",
            position: "Events",
            imageSrc: "assets/img/team/deputydirectors/Events_Dhivya_Dharshini.jpg",
            linkedinUrl: "https://www.linkedin.com/in/dhivya-dharshini-8b792a237"
          },
          {
            name: "Shantha Priya M",
            position: "Events",
            imageSrc: "assets/img/team/deputydirectors/Events_Shantha_Priya.jpg",
            linkedinUrl: "https://www.linkedin.com/in/shantha-priya-manikandan-3b506821b"
          },
          {
            name: "Shiyam Ganesh T",
            position: "Events",
            imageSrc: "assets/img/team/deputydirectors/Events_Shiyam_Ganesh.jpg",
            linkedinUrl: "https://www.linkedin.com/in/shiyam-ganesh-t-b7b0b7267/"
          },
          {
            name: "Ansh Bomb",
            position: "Events",
            imageSrc: "assets/img/team/deputydirectors/Events_Ansh_Bomb.jpg",
            linkedinUrl: "https://www.linkedin.com/in/ansh-bomb-6b4a36219/"
          },
          {
            name: "Harish Kummar K G S",
            position: "HR",
            linkedinUrl: "https://www.linkedin.com/in/harish-kummar-k-g-s-996b50209",
            imageSrc: "assets/img/team/deputydirectors/HR_Harish.jpeg"
          },
          {
            name: "Varsha B",
            position: "HR",
            linkedinUrl: "https://www.linkedin.com/in/varshacse",
            imageSrc: "assets/img/team/deputydirectors/HR_Varsha.jpg"
          },
          {
            name: "Ananya K A",
            position: "Content",
            linkedinUrl: "https://www.linkedin.com/in/ananya-k-a-735742232/",
            imageSrc: "assets/img/team/deputydirectors/Content_ Ananya.jpg"
          },
          {
            name: "Ramanan S J",
            position: "Content",
            linkedinUrl: "https://www.linkedin.com/in/ramanansj/",
            imageSrc: "assets/img/team/deputydirectors/Content_Ramanan.jpeg"
          },
          {
            name: "Dhusyanth Karthic S",
            position: "Marketing",
            linkedinUrl: "https://www.linkedin.com/in/dhusyanth-karthic-b63a8922b/",
            imageSrc: "assets/img/team/deputydirectors/Marketing_Dhusyanth.png"
          },
          {
            name: "Ajai Krishna T S",
            position: "Public Relations",
            linkedinUrl: "https://www.linkedin.com/in/ajai-krishna-t-s-4b3508243/",
            imageSrc: "assets/img/team/deputydirectors/PR_Ajai_Krishna.jpeg"
          },
          {
            name: "Dharsan S",
            position: "CP Wing",
            linkedinUrl: "https://www.linkedin.com/in/dharsans2004",
            imageSrc: "assets/img/team/deputydirectors/CP_Dharsan.jpg"
          },

          {
            name: "Nivedha B",
            position: "CP Wing",
            imageSrc: "assets/img/team/deputydirectors/CP_Nivedha.jpeg",
            linkedinUrl: "https://www.linkedin.com/in/nivedha04"
          }
    ]
    
    return(
        <div className={styles.body_wrapper} style={{backgroundImage:'url(assets/img/polygon.svg)'}}>
        <h1 className="section-header">Directors</h1>

        <div className={styles.card_container}>
          {directors.map((director) => 
          <>
          <TeamCard name={director.name} position={director.position} imageSrc={director.imageSrc} linkedinUrl={director.linkedinUrl}/>
          </>)}
        </div>

        <h1 className="section-header">Deputy Directors</h1>

        <div className={styles.card_container}>

          {deputyDirectors.map((deputyDirector) => 
          <>
          <TeamCard name={deputyDirector.name} position={deputyDirector.position} imageSrc={deputyDirector.imageSrc} linkedinUrl={deputyDirector.linkedinUrl}/>
          </>)}
        </div>
        </div>
    )
}

export default Team;

