import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from '../styles/HomeCard.module.css'

const HomeCard = ({heading, content,imgUrl,reverse}) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
        <div className={styles.event_container} data-aos="fade-right">
            <div className={styles.event_content}>
                <h2>{heading}</h2>
                <p>{content}</p>
            </div>
            <div className={styles.event_image}>
                <img src={imgUrl} alt="an event image" data-aos="fade-left"/>
            </div>
        </div>
    )
}

export default HomeCard;